import React, { FC } from 'react';
import classNames from 'classnames';
import HTag from 'components/common/HTag';
import getDropDownItem from 'utils/dropDownControl';
import { IProductFeatureProps } from './models';
import ProductFeatureItem from './ProductFeatureItem';
import './ProductFeatures.scss';

const ProductFeatures: FC<IProductFeatureProps> = ({ titleBold, titleRegular, features }) => {
  const featureCount = features?.length ?? 0;
  const featuresList = features?.map((feature, index) => (
    <ProductFeatureItem
      key={`${getDropDownItem(feature.properties.imageAlias)}-${index}`}
      featureCount={featureCount}
      {...feature.properties}
    />
  ));
  const listClass = classNames('dt-features__list', {
    'dt-features__list--centered': featureCount < 4,
  });

  return (
    <div className="dt-features">
      <HTag size={3} regularText={titleRegular} boldText={titleBold} />
      <nav className={listClass}>{featuresList}</nav>
    </div>
  );
};

export default ProductFeatures;
