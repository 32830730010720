import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import getDropDownItem from 'utils/dropDownControl';
import { ProductFeatureItemProps } from './models';
import './ProductFeatureItem.scss';

const ProductFeatureItem: FC<ProductFeatureItemProps> = ({
  description,
  descriptionRte,
  imageAlias,
  featureCount,
}) => {
  const xlCol = featureCount > 4 ? 2 : 3;
  const lgCol = featureCount > 4 ? 4 : 3;

  return (
    <Col xs={6} sm={6} md={4} lg={lgCol} xl={xlCol}>
      <div className="dt-features__list-item">
        <span className="dt-features__list-image">
          <IconCustom icon={getDropDownItem(imageAlias)} />
        </span>
        <DangerouslySetInnerHtml
          element={description ? 'p' : 'div'}
          html={description || descriptionRte}
          className="dt-features__list-item-text"
        />
      </div>
    </Col>
  );
};

export default ProductFeatureItem;
